import React from 'react'
// import { Link } from 'react-router-dom'

import { CalendarComponent } from '../components/CalendarComponent'

import './styles/dashboard.css'

const Dashboard = ()=>{
    return(
        <div className='content-container container-row'>
            <div className="calendar-container">
                <CalendarComponent/>

            </div>
            <div className="actions-container">

            </div>

            
        </div>
    )

}


export default Dashboard