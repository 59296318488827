import { BrowserRouter, Route, Routes} from 'react-router-dom'

import Home from './screens/Home.js'
import { System } from './screens/System.js';

import './App.css';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/app/*" element={<System/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
