import React from 'react'
import { FaChartBar, FaReceipt, FaUserCircle, FaGift } from 'react-icons/fa';
import { AiFillSetting, AiOutlineMenuFold } from "react-icons/ai";
import { IoLogOut } from "react-icons/io5";

import './styles/sidebar.css'

import { Link } from 'react-router-dom'


// const iconImage = 'https://media.istockphoto.com/id/1210939712/es/vector/icono-de-usuario-icono-de-personas-aislado-sobre-fondo-blanco-ilustración-vectorial.jpg?s=1024x1024&w=is&k=20&c=fKpdNnKU3TQBxSKwDmS_QFyZdN8oqRVMLgvsDwVKuo8='

const iconSize = 25

export const SideBar = () => {

  return (
    <div className='sidebar'>
        <div className='sidebar-logo'>
            <h1 className="sidebar-logo--title">HiCUD</h1>
            <AiOutlineMenuFold size={iconSize-2} className="sidebar-option--icon"/>
        </div>

        <div className='sidebar-menu'>
            <Link to="/app/dashboard">
                <div className="sidebar-option">
                    <FaChartBar size={iconSize}className="sidebar-option--icon"/>
                    <h2 className="sidebar-option--title">Dashboard</h2>
                </div>
            </Link>

            <Link to="/app/pacientes">
                <div className="sidebar-option">
                    <FaUserCircle size={iconSize} className="sidebar-option--icon"/>
                    <h2 className="sidebar-option--title">Pacientes</h2>
                </div>
            </Link>

            <Link to="/app/citas">
                <div className="sidebar-option">
                    <FaReceipt size={iconSize} className="sidebar-option--icon"/>
                    <h2 className="sidebar-option--title">Citas</h2>
                </div>
            </Link>

            <Link to="/app/recompensas">
                <div className="sidebar-option">
                    <FaGift size={iconSize} className="sidebar-option--icon"/>
                    <h2 className="sidebar-option--title">Recompensas</h2>
                </div>
            </Link>
            <hr className="sidebar-options--divider" />
            <Link to="/app/ajustes">
                <div className="sidebar-option">
                    <AiFillSetting size={iconSize} className="sidebar-option--icon"/>
                    <h2 className="sidebar-option--title">Ajustes</h2>
                </div>
            </Link>

            <div className="sidebar-option">
                <IoLogOut size={iconSize} className="sidebar-option--icon"/>
                <h2 className="sidebar-option--title">Logout</h2>
            </div>



        </div>
    </div>
  )
}
