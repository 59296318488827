import React from 'react'


export const Settings = () => {
  return (
    <div className='content-container'>
      
   
    </div>
  )
}
