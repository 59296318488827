import React from 'react'



export const Rewards = () => {
  return (
    <div className='content-container'>
  
   
    </div>
  )
}
