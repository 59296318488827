import React, {useState} from 'react'
import Calendar from 'react-calendar'

import { FaArrowUp, FaArrowDown } from "react-icons/fa";

import './styles/calendar.css'


export const CalendarComponent = () => {
  const [date, setDate] = useState(new Date())


  return (
  <div className="calendar-widget">
    <Calendar locale='ES' onChange={setDate} value={date}/>
    <div className="calendar-widget--meetings">
      <div className="meetings-text">
        <h4 className='meetings-title'>Citas del día</h4>
        <span className="meetings-number">3</span>
      </div>

      <div className="meeting-tile">
        <div className="meeting-info">
          <div className="meeting-info--user">
            <h4 className='meeting-hour'>14:30</h4>
            <h4 className="meeting-patient">Roberto Ibarra</h4>
          </div>
          <div className="navigate-meetings">
            <FaArrowUp size={18} className='arrow-icons'/>
            <FaArrowDown size={18} className='arrow-icons'/>
          </div>
        </div>  
        <div className="meeting-reschedule">Reagendar</div>
      </div>
    </div>
  </div>
  )
}
