import React from 'react'


export const Appointments = () => {
  return (
    <div className='content-container'>
     
      hola
    </div>
  )
}
