import React from 'react'
import { Route, Routes} from 'react-router-dom'

import { SideBar } from '../components/SideBar'
// import { SidebarBT } from '../components/SidebarBT'

import { Clients } from './Clients.js';
import  Dashboard  from './Dashboard.js';
import {Appointments} from './Appointments.js';
import { Rewards } from './Rewards.js';
import { Settings } from './Settings.js';


export const System = () => {
  return (
    <div className='wrapper'>
        <SideBar/>
        {/* <BrowserRouter> */}
        <div className="app-container">
            <Routes>
                <Route path="/dashboard" element={<Dashboard/>}/>
                <Route path="/pacientes" element={<Clients/>}/>
                <Route path="/citas" element={<Appointments/>}/>
                <Route path="/recompensas" element={<Rewards/>}/>
                <Route path="/ajustes" element={<Settings/>}/>

            </Routes>
        </div>
        {/* </BrowserRouter> */}
    </div>
  )
}
